import React, { FC } from 'react';

import './LandingLayout.scss';
import { graphql, useStaticQuery } from 'gatsby';
import Seo from 'components/common/Seo';
import { TStaticProps } from 'components/Layout/models';
import PageSchema from 'components/common/PageSchema';
import ILandingLayout from './models';

const LandingLayout: FC<ILandingLayout> = ({ seo, siteSettings, url, children }) => {
  const { brandSettings }: TStaticProps = useStaticQuery(graphql`
    {
      brandSettings {
        brandName
        brandLogo {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        brandLogoSchema
        youtubeUrl
        facebookUrl
      }
    }
  `);

  return (
    <div className="landing-page">
      <Seo
        brandSettings={brandSettings}
        siteSettings={siteSettings}
        description={seo.seoMetaDescription}
        keywords={seo.seoMetaKeywords}
        seoNoIndex={seo.seoNoIndex}
        title={seo.seoMetaTitle}
        ogImage={seo.seoImage}
        externalHreflangs={seo.seoExternalHreflangs}
        url={url}
      />
      <link rel="preconnect" href={process.env.GATSBY_IMAGE_CLOUD_SERVER} />
      <main>
        <PageSchema
          type="WebPage"
          siteSettings={siteSettings}
          brandSettings={brandSettings}
          name={seo?.seoMetaTitle}
          data={{
            metaTitle: seo?.seoMetaTitle,
            metaDescription: seo?.seoMetaDescription,
            metaKeywords: seo?.seoMetaKeywords,
          }}
        />
        {children}
      </main>
    </div>
  );
};

export default LandingLayout;
