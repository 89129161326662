import React, { FC } from 'react';

import './LandingHow.scss';
import { Link } from 'gatsby';
import UmbracoImage from 'components/common/Image/UmbracoImage';
import { ILandingHow } from './models';

const LandingHow: FC<ILandingHow> = ({ heading, instructions, link, image }) => (
  <div className="lp-how-to-use">
    <div className="lp-how-to-use__list-container">
      <div className="lp-how-to-use__heading">{heading}</div>
      <ol className="lp-how-to-use__list">
        {instructions?.map((instruction, idx) => (
          <li key={idx} className="lp-how-to-use__list-item">
            {instruction.value}
          </li>
        ))}
      </ol>
      <div className="lp-how-to-use__link-container">
        <Link to={`${link[0].url}${link[0].queryString}`} className="btn lp-button--read-more">
          {link[0].name}
        </Link>
      </div>
    </div>
    <div className="lp-how-to-use__claim">
      <UmbracoImage image={image} alt="alt" className="lp-how-to-use__claim-image" />
    </div>
  </div>
);

export default LandingHow;
