import React, { FC } from 'react';
import UmbracoImage from 'components/common/Image/UmbracoImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import { ILandingBannerAlternative } from './models';
import './LandingBannerAlternative.scss';

const LandingBannerAlternative: FC<ILandingBannerAlternative> = ({
  heading,
  backgroundImage,
  backgroundImageMobile,
  productImageDesktop,
  productImageMobile,
  finePrint,
  productImageDescription,
}) => (
  <div className="lp-banner-alternative">
    <div className="lp-banner-alternative__image-holder lp-banner-alternative__desktop-bg lp-banner-alternative__desktop-element">
      <UmbracoImage image={backgroundImage} alt={backgroundImage.altText} />
      <div className="lp-banner-alternative__image-overlay" />
    </div>
    <div className="lp-banner-alternative__image-holder lp-banner-alternative__mobile-bg lp-banner-alternative__mobile-element">
      <UmbracoImage image={backgroundImageMobile} alt={backgroundImageMobile.altText} />
      <div className="lp-banner-alternative__image-overlay" />
    </div>

    <div className="lp-banner-alternative__content">
      <UmbracoImage
        image={productImageMobile}
        alt={productImageMobile.altText}
        className="lp-banner-alternative__product-image--mobile lp-banner-alternative__mobile-element"
      />

      <DangerouslySetInnerHtml className="lp-banner-alternative__heading" html={heading} />
      <div className="lp-banner-alternative__fineprint">{finePrint}</div>
    </div>
    <UmbracoImage
      image={productImageDesktop}
      alt={productImageDesktop.altText}
      className="lp-banner-alternative__product-image--desktop lp-banner-alternative__desktop-element"
    />

    <div className="lp-banner-alternative__product-description">
      <DangerouslySetInnerHtml html={productImageDescription} />
    </div>
  </div>
);

export default LandingBannerAlternative;
