import React, { FC } from 'react';
import './LandingVideo.scss';
import { ILandingVideo } from './models';

const LandingVideo: FC<ILandingVideo> = ({ heading, video }) => (
  <div className="lp-video">
    <div className="lp-video__heading">{heading}</div>
    <div className="lp-video__video-container">
      <div className="lp-video__video-section">
        <iframe
          title="landing video"
          src={`https://www.youtube.com/embed/${video}`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope;"
          allowFullScreen
        />
      </div>
    </div>
  </div>
);

export default LandingVideo;
