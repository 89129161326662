import React, { FC } from 'react';
import { graphql } from 'gatsby';

import 'styles/main.scss';
import './LandingPage.scss';
import LandingDisclaimers from 'components/LandingDisclaimers';
import LandingHeader from 'components/LandingHeader';
import LandingBanner from 'components/LandingBanner';
import LandingBannerAlternative from 'components/LandingBannerAlternative';
import LandingOnTheGo from 'components/LandingOnTheGo';
import LandingVideo from 'components/LandingVideo';
import LandingHow from 'components/LandingHow';
import LandingMore from 'components/LandingMore';
import LandingFeatures from 'components/LandingFeatures';
import LandingLayout from 'components/LandingLayout';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import { Container, Row } from 'react-bootstrap';
import { ILandingPage } from './models';

const COMPONENTS = {
  landingHeader: 'Landing Header',
  footer: 'Footer',
  how: 'How',
  banner: 'Banner',
  more: 'More',
  features: 'Features',
  bannerAlternative: 'Banner Alternative',
  onTheGo: 'On The Go',
  video: 'Video',
  textBlock: 'Text Block',
};

const LandingPage: FC<ILandingPage> = ({ data }) => (
  <LandingLayout
    seo={{
      seoNoIndex: data.page.seoNoIndex,
      seoMetaTitle: data.page.seoMetaTitle,
      seoMetaDescription: data.page.seoMetaDescription,
      seoMetaKeywords: data.page.seoMetaKeywords,
      seoCanonicalUrl: data.page.seoCanonicalUrl,
      seoExternalHreflangs: data.page.seoExternalHreflangs,
      seoImage: data.page.seoImage,
      ogPageType: data.page.ogPageType,
    }}
    siteSettings={data.siteSettings}
    url={data.page.link}
  >
    {data.page.landingPageBody?.map((item, idx) => {
      switch (item.structure) {
        case COMPONENTS.landingHeader:
          return <LandingHeader key={idx} {...item} />;

        default: {
          return null;
        }
      }
    })}

    <div className="landing-content">
      {data.page.landingPageBody?.map((item, idx) => {
        switch (item.structure) {
          case COMPONENTS.footer:
            return <LandingDisclaimers key={idx} {...item} />;

          case COMPONENTS.how:
            return <LandingHow {...item} key={idx} />;

          case COMPONENTS.banner:
            return <LandingBanner {...item} key={idx} />;

          case COMPONENTS.more:
            return <LandingMore {...item} key={idx} />;

          case COMPONENTS.features:
            return <LandingFeatures {...item} key={idx} />;

          case COMPONENTS.bannerAlternative:
            return <LandingBannerAlternative {...item} key={idx} />;

          case COMPONENTS.onTheGo:
            return <LandingOnTheGo {...item} key={idx} />;

          case COMPONENTS.video:
            return <LandingVideo {...item} key={idx} />;

          case COMPONENTS.textBlock: {
            return (
              <Container key={idx}>
                <Row className="justify-content-md-center">
                  <DangerouslySetInnerHtml
                    html={item.articleSectionDescription}
                    className="dt-article-main-text"
                  />
                </Row>
              </Container>
            );
          }

          default:
            return null;
        }
      })}
    </div>
  </LandingLayout>
);

export const query = graphql`
  query LandingPageQuery($link: String!, $lang: String) {
    languageSelector(lang: { eq: $lang }) {
      ...FragmentLanguageSwitcher
    }
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
      titleArticlePagesRecommendedProducts
    }

    page: umbracoLandingPage(link: { eq: $link }, lang: { eq: $lang }) {
      seoNoIndex
      seoMetaTitle
      seoMetaDescription
      seoMetaKeywords
      seoCanonicalUrl
      seoImage
      ogPageType
      seoExternalHreflangs {
        key
        value
      }
      link
      landingPageBody {
        articleSectionDescription
        leftSubHeading
        rightSubHeading
        link {
          name
          url
          queryString
        }
        menuLinks {
          name
          url
          queryString
        }
        backgroundImage {
          altText
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        backgroundImageMobile {
          altText
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        productImageDesktop {
          altText
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        productImageMobile {
          altText
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        image {
          altText
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        heading
        subHeading
        structure
        instructions {
          value
        }
        disclaimers {
          value
        }

        features {
          structure
          properties {
            iconName
            description
          }
        }

        leftFeatures {
          structure
          properties {
            iconName
            description
          }
        }

        rightFeatures {
          structure
          properties {
            iconName
            description
          }
        }
        finePrint
        productImageDescription
        onTheGoHeading
        onTheGoDescription
        cssClassName
        video
      }
    }
  }
`;

export default LandingPage;
