import React, { FC } from 'react';

import { TStaticProps } from 'components/Layout/models';
import { Nav, Navbar } from 'react-bootstrap';
import './LandingHeader.scss';
import Logo from 'components/common/Logo';
import { graphql, useStaticQuery } from 'gatsby';
import { ILandingHeader } from './models';

const LandingHeader: FC<ILandingHeader> = ({ menuLinks, link }) => {
  const { brandSettings }: TStaticProps = useStaticQuery(graphql`
    {
      brandSettings {
        brandName
        brandLogo {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        brandLogoSchema
        youtubeUrl
        facebookUrl
      }
    }
  `);

  return (
    <div className="lp-header">
      <Logo brandSettings={brandSettings} />
      <Navbar expand="lg">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto lp-header__main-items">
            {menuLinks?.map((navItem, idx) => (
              <Nav.Link
                key={idx}
                className="lp-header__nav-item"
                href={navItem.url ? navItem.url : `${navItem.queryString}`}
              >
                {navItem.name}
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div className="lp-header__buy-now">
        <div className="shopping-cart-icon" />
        <a href={link[0].url} target="_blank" rel="noreferrer">
          {link[0].name}
        </a>
      </div>
    </div>
  );
};

export default LandingHeader;
