import React, { FC } from 'react';

import IconCustom from 'components/common/IconCustom';
import { ILandingFeatures } from './models';
import './LandingFeatures.scss';

const LandingFeatures: FC<ILandingFeatures> = ({ heading, subHeading, features }) => {
  return (
    <div className="lp-features">
      <div className="lp-features__heading">{heading}</div>
      <div className="lp-features__subheading">{subHeading}</div>
      <div className="lp-features__list">
        {features?.map((feature, idx) => (
          <div key={idx} className="lp-features__item">
            <IconCustom icon={feature.properties.iconName} />
            <div className="lp-features__item-text">{feature.properties.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LandingFeatures;
