import React, { FC } from 'react';

import './LandingOnTheGo.scss';
import UmbracoImage from 'components/common/Image/UmbracoImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import useScreenRecognition from 'hooks/useScreenRecognition';
import { ILandingOnTheGo } from './models';

const LandingOnTheGo: FC<ILandingOnTheGo> = ({ onTheGoHeading, image, onTheGoDescription }) => {
  const { isMobile } = useScreenRecognition();

  return (
    <div className="lp-otg">
      <div className="lp-otg__container">
        {!isMobile ? (
          <div className="lp-otg__image-container lp-otg__image-container--desktop">
            <UmbracoImage image={image} alt={image.altText} className="lp-otg__product-image" />
          </div>
        ) : null}

        <div className="lp-otg__content">
          <div className="lp-otg__heading">{onTheGoHeading}</div>
          {isMobile ? (
            <div className="lp-otg__image-container lp-otg__image-container--mobile">
              <UmbracoImage image={image} alt={image.altText} className="lp-otg__product-image" />
            </div>
          ) : null}
          <DangerouslySetInnerHtml className="lp-otg__description" html={onTheGoDescription} />
        </div>
      </div>
    </div>
  );
};

export default LandingOnTheGo;
