import React, { FC } from 'react';

import { ILandingDisclaimers } from './models';
import './LandingDisclaimers.scss';

const LandingDisclaimers: FC<ILandingDisclaimers> = ({ heading, disclaimers }) => (
  <div className="lp-disclaimers">
    <div className="lp-disclaimers__content">
      <div className="lp-disclaimers__heading">{heading}</div>
      {disclaimers.map((disclaimer) => (
        <div key={disclaimer.value}>{disclaimer.value}</div>
      ))}
    </div>
  </div>
);

export default LandingDisclaimers;
