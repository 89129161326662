import React, { FC } from 'react';
import classNames from 'classnames';

import './LandingMore.scss';
import IconCustom from 'components/common/IconCustom';
import UmbracoImage from 'components/common/Image/UmbracoImage';
import { ILandingMore } from './models';

const LandingMore: FC<ILandingMore> = ({
  heading,
  leftSubHeading,
  rightSubHeading,
  leftFeatures,
  rightFeatures,
  link,
  image,
  cssClassName,
}) => (
  <div className={classNames('lp-more', { [`${cssClassName}`]: cssClassName })}>
    <h1 className="lp-more__heading">{heading}</h1>
    <div className="lp-more__row">
      <div className="lp-more__content">
        <div className="lp-more__subheading">{leftSubHeading}</div>
        {leftFeatures?.map((feature, idx) => (
          <div key={idx} className="lp-more__feature lp-more__feature--left">
            <IconCustom icon={feature.properties.iconName} />
            <div>{feature.properties.description}</div>
          </div>
        ))}
      </div>
      <div className="lp-more__image-container">
        <UmbracoImage image={image} alt={image.altText} className="lp-more__image" />
      </div>
      <div className="lp-more__content">
        <div className="lp-more__subheading">{rightSubHeading}</div>
        {rightFeatures?.map((feature, idx) => {
          return (
            <div key={idx} className="lp-more__feature">
              <IconCustom icon={feature.properties.iconName} />
              <div>{feature.properties.description}</div>
            </div>
          );
        })}
      </div>
    </div>
    <div className="lp-more__link-container">
      <a
        href={`${link[0].url}${link[0].queryString}`}
        className="btn lp-button--buy-now"
        target="_blank"
        rel="noreferrer"
      >
        {link[0].name}
      </a>
    </div>
  </div>
);

export default LandingMore;
