import React, { FC } from 'react';
import UmbracoImage from 'components/common/Image/UmbracoImage';
import { ILandingBanner } from './models';
import './LandingBanner.scss';

const LandingBanner: FC<ILandingBanner> = ({
  heading,
  backgroundImage,
  productImageDesktop,
  productImageMobile,
  finePrint,
}) => (
  <div className="lp-banner">
    <div className="lp-banner__image-holder">
      <UmbracoImage image={backgroundImage} alt="alt" />
      <div className="lp-banner__image-overlay" />
      <img className="lp-banner__extra-mobile-image" src="/images/kills99.svg" alt="" />
    </div>
    <div className="lp-banner__content">
      <UmbracoImage
        image={productImageMobile}
        alt="prodimm"
        className="lp-banner__product-image--mobile"
      />
      <div className="lp-banner__heading">{heading}</div>
      <div className="lp-banner__fineprint">{finePrint}</div>
      <UmbracoImage
        image={productImageDesktop}
        alt="prodimd"
        className="lp-banner__product-image--desktop"
      />
    </div>
  </div>
);

export default LandingBanner;
